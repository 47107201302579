import React from 'react'; 

export const ModalVideo = () => {
    return (
        <div id="modalvideo" className="modal">
	        <a href="#!" className="modal-action modal-close waves-effect waves-green btn-flat"><i className="icon-cancel-circle"></i></a>
            <div className="modal-content">
                <div className="video-container">
                    <p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/QlSruIuc2lQ?version=3&amp;enablejsapi=1"  frameBorder="0"></iframe>
                    </p>
                </div>
            </div>    
        </div>
    )
}