import React, { useState, useEffect }  from 'react';
import axios from 'axios';

import './GaleriaCademOnline.css';

export const GaleriaCademOnline = () => {

    const config = require('../../configuration/index');
    const URL_JSON_INSTAGRAM  = config.URL_JSON_INSTAGRAM;
    const API_SITIO_CADEMONLINE = config.API_SITIO_CADEMONLINE;

    const [images, setImages] =  useState([]);
    const [imagenesGaleria, setImagenesGaleria] = useState([]); 

    const handleDataInstagram = () => {

        axios.get(URL_JSON_INSTAGRAM)
        .then(function (response) {

            let count = response.data.graphql.hashtag.edge_hashtag_to_media.count;
            let edges = response.data.graphql.hashtag.edge_hashtag_to_media.edges;

            let imagenes = edges.slice(0, 9);

            setImages(imagenes);

        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })

    }

    function truncate(input) {
        if (input.length > 5) {
           return `${input.substring(0, 45)}...`;
        }
        return input;
     };

    useEffect(() => { 
        // handleDataInstagram();
        getListImages();
    },[]);

    const getListImages = async () => {
        await axios.get(API_SITIO_CADEMONLINE+'/sistema-panel-get-list-images')
        .then(response => {
                if(response.data.ok){                                  
                    setImagenesGaleria(response.data.imagenes);
                    console.log('response.data.imagenes', response.data.imagenes);
                }                
             })
             .catch(error => {
                 console.log(error)                 
             });
    }


    // return (
    //     <section className="color-9 section s9" id="section-4" style={{ paddingTop: '40px'}}>
	//         <main>
	// 	       <div className="container-flex"> 
    //                <div className="div-galeria1 row1">
    //                     <div className="valign-wrapper">
    //                          <div className="valign">
    //                              <h2>                                     
    //                                  <span>
    //                                      Comparte tu premio en Instagram usando el hashtag
    //                                      <strong>#CademOnline</strong>
    //                                 </span>
    //                              </h2>
    //                          </div>
    //                      </div>
    //                </div>
    //                <div className="div-galeria2 row2">
    //                    <div className="container-image">   
    //                         {
    //                         imagenesGaleria.map((item, i) => (
    //                             <div className="caja caja-img" key={item.ID_IMAGEN} 
    //                             style={{ backgroundImage: `url(${item.RUTA_IMAGEN})`}}>                                
    //                             </div>
    //                         ))
    //                         }                                                     
    //                     </div>                        
    //                </div>                   
    //            </div>
    //         </main>
    //     </section>
    // )

    return (
        <section className="color-9 section s9" id="section-4" style={{ paddingTop: '10px', paddingBottom: '10px'}}>
	        <main>
		       <div className="container-flex">
                   <div className="row1">
                        {/* <div className="valign-wrapper">
                             <div className="valign"> */}
                                 <h2>
                                     <span className="titlegaleria">
                                         Galería<strong>#CademOnline</strong>
                                    </span>
                                 </h2>
                             {/* </div>
                         </div> */}
                   </div>
                   <div className="row2">
                        {
                            imagenesGaleria.map((item, i) => (
                                <a
                                    key={item.ID_IMAGEN}
                                    href={item.URL_TO_HREF || ''}
                                    target="_blanck"
                                >
                                    <img
                                        src={item.RUTA_IMAGEN}
                                        className="gallery__photo"
                                    />
                                </a>
                            ))
                        }
                   </div>
               </div>
            </main>
        </section>
    )

};