import React from 'react';

import { Bienvenidos } from '../../views/Bienvenidos';
import { Ganadores } from '../../views/Ganadores';
import { GaleriaInstagram } from '../../views/GaleriaInstagram';
import { QueCademOnline } from '../../views/QueCademOnline';
import { PreguntasFrecuentes } from '../../views/PreguntasFrecuentes';
import { Contacto } from '../../views/Contacto/';
import { FormularioRegistroCademOnline } from '../../views/FormularioRegistro/index.js';
import { GaleriaCademOnline } from '../../views/GaleriaCademOnline/index.js';

export const Principal = () => {

    return (
        <>
        <div className="main" id="fullpage">
            <Bienvenidos />
            <FormularioRegistroCademOnline />
            <Ganadores />
            <GaleriaCademOnline />
            {/* <GaleriaInstagram /> */}
            <QueCademOnline />
            <PreguntasFrecuentes />
            <Contacto />
        </div>
        </>
    )
};