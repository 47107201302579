import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Header } from '../components/ui/Header';
import { GanadoresList } from '../views/Ganadores/GanadoresList';
import {  Principal } from '../components/principal';

export const DashboardRoutes = () => {
    return (
        <>
            <Header />
            <div className="main" id="fullpage">
                <Switch>
                    <Route exact path="/ganadores" component={ GanadoresList } />
                    <Route path="/" component={ Principal } />

                </Switch>
            </div>
        </>
    )
}
