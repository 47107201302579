import React, { useState, useEffect } from 'react';
import data from './data/index';

export const PreguntasFrecuentes = () => {

    const [result, setResult] = useState([]);

    const handleSelect = (e) => {

        let id = (e == 0) ? 0 : e.target.value;
        console.log('id', id);
        
        let resultados = data.filter( element => element.id == id);

        setResult(resultados);
    };

    useEffect(() => {
        handleSelect(0);
    }, [])

    return (
        <section className="color-7 section" id="section-6" style={{ paddingTop: '50px'}}>
		    <main>
			    <div className="row">
			        <div className="col m5 s12">
				        <h6 className="sub-title"></h6>
				        <div className="valign-wrapper">
                            <div className="valign" style={{ zIndex: '99'}}>
					            <div className="input-field">
                                    <select 
                                        className="browser-default black-select select-jump"                                        
                                        onChange={handleSelect}>
                                        <option value="0">Consultas y dudas</option>
                                        <option value="1">¿Qué pasa si quiero dejar de ser miembro?</option>
                                        <option value="2">¿Mi información personal está protegida?</option>
                                        <option value="3">¿Todo es por internet?</option>
                                        <option value="4">¿Tengo que pagar algo?</option>
                                        <option value="5">¿Cuántas encuestas tengo que responder?</option>
                                        <option value="6">¿Cuánto se demora en contestar una encuesta?</option>
                                        <option value="7">¿Quiénes pueden participar?</option>
                                        <option value="8">¿Cómo funciona?</option>
                                        <option value="9">¿Qué beneficios tengo por participar?</option>
                                        <option value="10">¿Cómo participar?</option>
                                        <option value="11">¿Por qué al contestar la primera pregunta se cierra la encuesta?</option>
                                    </select>
					            </div>
				            </div>
                        </div>
			        </div>
			        <div className="col m7 s12">
			            <div className="valign-wrapper">
                            <div className="valign">
                            {result.map((r, i) => 
				                <div className="grande" key={i}>
                                    <h6 className="title">{r.title}</h6>
                                    <p dangerouslySetInnerHTML={ {__html: r.subtitle} } ></p>
				                </div>
                                )}
			                </div>
                        </div>
			        </div>
                </div>
		    </main>
	    </section>
    )
}


