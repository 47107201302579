import React,  { useState, useEffect } from 'react';
import { HeaderGanadores } from '../../components/ui/HeaderGanadores';
import { getGanadores }  from './data/getGanadores';
import helpers from '../../utils/helpers';
import queryString from 'query-string';

import MUIDataTable from "mui-datatables";

import 'moment/locale/es';
var moment = require('moment');
moment.locale('es');

export const GanadoresList = () => {

    const [data, setData] = useState([]);
    const [years, setYears] = useState([]);
    const [meses, setMeses] = useState([]);

    const { nombre , estudio , mes  , anio  } = queryString.parse( window.location.search );

    const [stateForm, setStateForm] = useState(
        {
            nombre: nombre,
            estudio: estudio,
            mes: mes,
            anio : anio,
        }
    );


    const onChangeForm = (e) => {
        setStateForm({
            ...stateForm,
            [e.target.name] : e.target.value,
        });
    }

    useEffect(() => {

        getGanadores().then(function(result) {

            let rows= [];

            result.forEach((element, index) => {

                // let mes = moment(element.FECHA_SORTEO).format('MMMM').toUpperCase();

                ///////////////////////////////////////////////////////////////////////////
                let day = element.FECHA_SORTEO.split("-")[1];
                let month = element.FECHA_SORTEO.split("-")[0];                
                let year = element.FECHA_SORTEO.split("-")[2];
                let fechaFormat =  `${year}-${month}-${day}`                
                const vFecha = new Date(fechaFormat);                
                let mes = vFecha.toLocaleString('es-ES', { month: 'long' }).toLocaleUpperCase().toString();
                ///////////////////////////////////////////////////////////////////////////

                let mes_numerico = moment(element.FECHA_SORTEO).format('MM')

                    rows[index] = {
                        nombre: element.NOMBRE_GANADOR,
                        email: element.EMAIL,
                        estudio: element.ENCUESTA,
                        timestamp:  moment(element.FECHA_SORTEO).format('x'),
                        mes: mes,
                        year: element.YEAR,
                        mes_numerico : mes_numerico,
                        premio: element.PREMIO,
                        fecha_real : element.FECHA_SORTEO
                    };
            });
            
            if(window.location.search == "?nombre=&estudio=&mes=&anio=" || window.location.search == "?nombre=&estudio=&mes&anio")
            {
                rows = rows;
            }else{
                rows = JSON.search(rows, '//*[(contains(nombre, '+ '"' + nombre  + '"' + ')) and (contains(estudio, '+ '"' + estudio  + '"' + ')) and (contains(mes_numerico, '+ '"' + mes  + '"' + ')) and (contains(year, '+ '"' + anio  + '"' + '))]');
            }
    
            setData(rows);

        });

        setYears(helpers.helper_years());
        setMeses(helpers.helpers_meses());
        

    },[]);

    const columns = [
        {
            name: "nombre",
            label: "Nombre"
        },
        {
            name: "estudio",
            label: "Estudio o Campaña"
        },
        {
            name: "mes",
            label: "Mes"
        },
        {
            name: "year",
            label: "Año"
        },
        {
            name: "premio",
            label: "Premio"
        }
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: "No se encontraron registros",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
                },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Registros por pagina:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver en Columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todas",
                title: "Filtros",
                reset: "Limpiar",
            },
            viewColumns: {
                title: "Mostrar",
                titleAria: "Mostrar/Ocultar columnas de tabla",
            },
        },
            searchPlaceholder : "Buscar ...",
            caseSensitive : false,
            selectableRows: false,
            download : false,
            print: false,
            filter: false,
            viewColumns: false,
            search: false
        };

    return (
        <div style={{  backgroundColor: '#eaeaea' }}>
            <HeaderGanadores />
            <section className="s1-gana">
                <div style={{  paddingTop:'50px' }}>
                <h1>GANADORES</h1>
                </div>
                <form className="form-gana">
                    <div className="tabla-filtro">
                        <div className="filtro-caja">
                            <label>Nombre</label>
                            <input type="text" id="gana-nombre" name="nombre" placeholder="Ej: Raúl Encina" onChange={onChangeForm} value={stateForm.nombre} />
                        </div>
                        <div className="filtro-caja">
                            <label>Estudio o Campaña</label>
                            <input type="text" id="gana-estudio" name="estudio" placeholder="Ej: Estudio N°234" onChange={onChangeForm} value={stateForm.estudio} />
                        </div>
                        <div className="filtro-caja" style={{ paddingBottom: '27px' }}>
                            <label>Mes</label>
                            <option value="">Seleccione</option>
                            <select id="gana-mes" name="mes" className="browser-default" onChange={onChangeForm} value={stateForm.mes || ''}>
                                <option value="">Seleccione</option>
                                {meses.map((mes) => 
                                    <option value={mes.id} key={mes.mes}>{mes.mes}</option>
                                )}
                            </select>
                        </div>
                        <div className="filtro-caja" style={{ paddingBottom: '27px' }}>
                            <label>Año</label> 
                            <select id="gana-anio" name="anio" className="browser-default" onChange={onChangeForm} value={stateForm.anio || ''}>
                                <option value="">Seleccione</option>
                                {years.map((year) =>
                                    <option value={year.value} key={year.value}>{year.value}</option>
                                )}
                            </select>
                        </div>
                        <div className="filtro-caja" style={{ paddingBottom: '27px' }}>
                            <button type="submit">BUSCAR</button>
                        </div>
                    </div>
                </form>
                <div style={{ paddingTop : '10px'}}>
                    <MUIDataTable
                        title={""}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </div>
            </section>
        </div>
    )
}