import { Hidden } from '@material-ui/core';
import React, {useEffect} from 'react'; 

const mobile = require('is-mobile');

export const FormularioRegistroCademOnline = () => {

    const config = require('../../configuration/index');
    const URL_FORMULARIO_CADEMONLINE  = config.URL_FORMULARIO_CADEMONLINE;

    useEffect(() => { 
        
        console.log('mobileXXX', mobile());
        // document.querySelector('#id_formulario_cadem_online')
        // .contentWindow.document.querySelector("#idroot").style.backgroundColor= "red";
        // console.log('SELECTORXXX', document.querySelector('#id_formulario_cadem_online').contentWindow.document.querySelector("body"))
    }, [])

    return (
        <section className="color-2 section fp-section fp-table  section" id="section-2">
            <div className="fp-tableCell">
	           {/* <main className="valign-wrapper force-main container-flex"> */}
               <main className="container-flex">
               <iframe
                    id="id_formulario_cadem_online"
                    width= {mobile() ? "100%" : "60%"}
                    height="99%"
                    src={URL_FORMULARIO_CADEMONLINE}                     
                    frameBorder="0"
                    // scrolling="yes"
                    scrolling= {mobile() ? "yes" : "no"}
                    allowFullScreen 
                    seamless="seamless"
                    allowtransparency="true"
                    style={{ overflowX : "hidden", overflowY: 'hidden', margin:'0px', 
                    padding:'0px', overflow:'hidden', zIndex:0, 
                    pointerEvents:'visibleStroke'
                }}                    
                />
	            </main>
            </div>
            
        </section>
    )

};