import React from 'react';

export const HeaderGanadores = () => {
    return (
        <div className="navbar-fixed">
	        <nav className="white">
		        <div className="nav-wrapper">
			        <a href="/" target="_top" className="brand-logo"><img src="/img/logo-cadem-online.svg" /></a>
		        </div>	
	        </nav>
        </div> 
    )
}