import React, { useState, useEffect } from 'react';
import { getGanadores }  from './data/getGanadores';

export const Ganadores = () => {

    var moment = require('moment');
    const f = new Date();

    let mes_anterior = moment(f).subtract(1, 'months').format('MM');
    let mes_actual = f.getMonth() + 1;
    let year_anterior = (mes_actual == 1) ? f.getFullYear() - 1 : f.getFullYear();
    let year_actual = f.getFullYear(); 
    let monthNames = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const [GanadoresActuales, setStateGanadoresActuales] = useState([]);
    const [GanadoresAnterior, setStateGanadoresAnterior] = useState([]);
    const [stateMesActualStr] = useState(monthNames[mes_actual]);
    //si mes anterior contiene un 0 al inicio remover para que pueda buscar el nombre en el array por indice  
    const [stateMesAnteriorStr] = useState(monthNames[parseInt((mes_anterior.charAt(0) == 0) ? mes_anterior.substr(1,2) : mes_anterior)]);
    const [stateCantidadGanadores, setStateCantidadGanadores] = useState("");

    useEffect(() => {

        getGanadores().then(function(result) {
            let rows= [];            
            result.forEach((element, index) => {

                // let mes = moment(new Date(element.FECHA_SORTEO)).format('MM').toUpperCase();
                let day = element.FECHA_SORTEO.split("-")[1];
                let month = element.FECHA_SORTEO.split("-")[0];                
                let year = element.FECHA_SORTEO.split("-")[2];
                let fechaFormat =  `${year}-${month}-${day}`                
                const vFecha = new Date(fechaFormat);                
                let mes = vFecha.toLocaleString('es-ES', { month: 'long' }).toLocaleUpperCase().toString();

                let nombre = element.NOMBRE_GANADOR;
                if ( nombre.toLocaleUpperCase().toString().trim() != "NULL NULL" && nombre.toLocaleUpperCase().toString().trim() != "" ) {
                    rows[index] = {
                        nombre:  nombre,
                        mes: `${mes}`,
                        year: element.YEAR,
                        correo: element.CORREO_GANADOR
                    };
                }
            });
            
            // mes_actual = moment(new Date).format('MM');            
            const today = new Date();   
            console.log(today)           
            mes_actual = today.toLocaleString('es-ES', { month: 'long' }).toLocaleUpperCase().toString()
            
            // mes_anterior = today.setMonth(today.getMonth() - 1).toLocaleString('es-ES', { month: 'long' });              
            const previousMoutn = new Date(); 
            previousMoutn.setDate(previousMoutn.getDate() - (today.getDate() + 1) )

            // previousMoutn.setMonth(previousMoutn.getMonth() - 1)
            mes_anterior = previousMoutn.toLocaleString('es-ES', { month: 'long' }).toLocaleUpperCase().toString()
            console.log("Mes actual: ", mes_actual, ' | Mes anterior: ', mes_anterior)
            // previousMoutn.setMonth(previousMoutn.getMonth() - 1); 
            // mes_anterior = previousMoutn.toLocaleString('es-ES', { month: 'long' }).toLocaleUpperCase().toString();  
               
            
            // let actual = rows.filter(element =>  element.mes == `${mes_actual}` && element.year == `${year_actual}`);
            // let anterior = rows.filter(element =>  element.mes == `${mes_anterior}` && element.year == `${year_anterior}`);            
            let actual = rows.filter(element =>  element.mes == `${mes_actual}` && element.year == `${year_actual}`);
            let anterior = rows.filter(element =>  element.mes == `${mes_anterior}` && element.year == `${year_anterior}`);
            
            setStateGanadoresActuales(actual);
            setStateGanadoresAnterior(anterior);
            setStateCantidadGanadores(result.length);  
        });

    },[]);

    return (
        <section className="color-3 section s3" id="section-3">
	        <main>
		        <div className="row">
			        <div className="sub-title">
				        <div className="col s6">
					        <h6></h6>
				        </div>
                    </div>
                    <div className="col l5 m12 s12 5 txt s3-col s3-col1">			
                        <div className="valign-wrapper">
                            <div className="valign" style={{ zIndex: 99 }}>
                                <div className="big">
                                    <p className="s3-titulo">
                                        <span>Revisa si </span>
                                        <span><b>ganaste</b></span>
                                    </p>
                                    {/* <p className="s3-descripcion">
                                        <span>Busca por tu nombre o mail</span>
                                    </p> */}
                                </div>
                                {/* <div className="input-field">
                                    <form id="searchwin" action="/ganadores-detalles/" method="GET">	
                                        <div className="input-group">
                                            <input type="text" id="search-input" name="nombre" placeholder="Ej: Nombre del ganador" className="autocompleteu" />
                                            <input type="hidden" id="estudio" name="estudio" />
                                            <input type="hidden" id="mes" name="mes" />
                                            <input type="hidden" id="anio" name="anio" />
                                            <input type="hidden" id="orden" name="orden" value="fecha" />
                                            <button type="submit" className="sufix">
                                                <i className="material-icons icon-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                    <p className="s3-texto1">LLEVAMOS <span className="ganadores">{stateCantidadGanadores}</span> GANADORES</p>
                                    <p className="s3-texto2">¡TÚ PUEDES SER UNO!</p>
                                    <a href="/ganadores-detalles/?nombre=&estudio=&mes&anio" className="s3-btn">VER TODOS LOS GANADORES</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col l5 m12 s12 s3-col s3-col2">
                        <div className="valign-wrapper">
                            <div className="valign">
                                <h3 className="s3-h3">ÚLTIMOS GANADORES</h3>
                                <div className="s3-ganadores">
                                    <div className="s3-ganadores-col s3-ganadores1">
                                        <div className="s3-ganadores-in">
                                            <h4>{stateMesActualStr}</h4>
                                            {GanadoresActuales.map((data, i) => 
                                                {if(i <= 12)
                                                    return (
                                                    <ul className="anterior" key={i}>
                                                        <li>{data.nombre}</li>
                                                    </ul>
                                                    )
                                                }
                                            )}								
                                        </div>
                                    </div>
                                    <div className="s3-ganadores-col s3-ganadores1">
                                        <div className="s3-ganadores-in">
                                            <h4>{stateMesAnteriorStr}</h4>
                                            {GanadoresAnterior.map((data, i) => 
                                                {if(i <= 12)
                                                    return (
                                                    <ul className="ant_anterior" key={i}>
                                                        <li>{data.nombre}</li>
                                                    </ul>)
                                                }
                                            )}								
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
			        </div>
                </div>
	        </main>
	
	    </section>
    )
};