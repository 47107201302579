import React from 'react';

export const QueCademOnline = () => {
    return (
            <section className="color-6 section" id="section-5" style={{ color: '#FFF'}}>
		        <main>
			        <div className="row">
			            <div className="col m6 s12">
				            <h6 className="sub-title"></h6>
				                <div className="valign-wrapper">
                                    <div className="valign">
					                    <h2 className="big"><strong>¿Qué es Cadem Online?</strong></h2>
				                    </div>
                                </div>
			            </div>
			            <div className="col m6 s12">
			                <div className="valign-wrapper">
                                <div className="valign animated fadeInRight">
                                    <p>Somos una plataforma que reúne a miles de consumidores en todo Chile, quienes entregan su opinión sobre distintos temas de política, consumo, marcas, gustos, preferencias o estilos de vida a través de nuestras encuestas.</p>
                                    <p>Los integrantes del panel web demoran entre 5 y 10 minutos en contestar las encuestas e inmediatamente participan por interesantes premios, como giftcards, ipad, invitaciones a restaurantes, pasajes dentro de Chile y muchos más.</p>
                                    <p>Lo que más nos importa es conocer las opiniones de todos los chilenos.</p>
                                    <p><strong>CADEM ONLINE</strong> es parte de Cadem, una de las principales empresa de investigación de mercado y opinión pública en Chile, con 45 años de experiencia en el mercado.</p>
                                </div>
                            </div>
			            </div>
                    </div>
		        </main>
            </section>
    );
    
};