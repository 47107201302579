//base url sitio 
export const BASE_URL = "http://localhost:3000";

//ganadores 
export const URL_JSON_GANADORES = "https://storage.googleapis.com/ganadores_cademonline_sitio/ganadores.json";

//formulario cademonline 
// export const URL_FORMULARIO_CADEMONLINE = "http://192.168.0.20:3000/formulario-registro?tag=sitio";
// export const URL_FORMULARIO_CADEMONLINE = "http://localhost:3000/formulario-registro?tag=sitio";
export const URL_FORMULARIO_CADEMONLINE = "https://formulario.cademonline.cl/formulario-registro?tag=sitio"; 

//data instagram , se genera  1 vez al día en un bucket y lo sobreescribe siempre  
 export const URL_JSON_INSTAGRAM = "https://storage.googleapis.com/graphql_instagram_cademonline/tags_cademonline.json";

 //api envio correo
 export const API_SITIO_CADEMONLINE = "https://backend-sitio-cadem-online-ba7zkvym3a-ue.a.run.app";
//  export const API_SITIO_CADEMONLINE = "http://localhost:5000";
