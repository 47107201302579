import React, {  useState } from 'react';
import axios from 'axios';
 
export const Contacto  = () =>  {

    const config = require('../../configuration/index');
    const API_SITIO_CADEMONLINE  = config.API_SITIO_CADEMONLINE;

    const [stateForm, setStateForm] = useState(
        {
            nombre: '',
            email: '',
            mensaje: '',
            error : {},
        }
    );

    const [statemessage, setStateMessage] = useState("");

    const onChangeForm = (e) => {
        setStateForm({
            ...stateForm,
            [e.target.name] : e.target.value,
            error:{
                ...stateForm.error,[e.target.name]:''
            }
        });
    }


    function validar_email (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        let validador = 0;
        let errores = [];

        if(stateForm.nombre.trim() == "")
        {
            errores["nombre"] = "El campo nombre es requerido";
            validador = 1;
        }
        if(validar_email(stateForm.email) == false)
        {
            errores["email"] = "El campo email es inválido";
            validador = 1;
        }

        if(stateForm.mensaje.trim() == "")
        {
            errores["mensaje"] = "El campo mensaje es requerido";
            validador = 1;
        }

        setStateForm({
            ...stateForm,
            error: errores ,
            validador

        });

        //si no ahi errores se hace la peticion al endpoint
        if(validador == 0)
        {
            axios.post(`${API_SITIO_CADEMONLINE}/send-message`, {
                nombre: stateForm.nombre,
                email: stateForm.email,
                mensaje: stateForm.mensaje
            })
            .then(function (response) {

                if(response.data.ok == true)
                {
                    setStateMessage("Mensaje enviado ..");

                    setStateForm({
                        ...stateForm,
                        nombre: '',
                        email: '',
                        mensaje: ''
                    });

                    setTimeout(() => {

                        setStateMessage("");

                    }, 2000);
                }

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
        }
        
    };

    return (
        <section className="color-8 section" id="section-7" style={{ backgroundColor : "#8031A7", color: '#FFF', paddingTop: '40px' }}>
            <div className="">
		        <main>
		            <div className="row">
			            <div className="col m7 s12">
				            <div className="valign-wrapper">
                                <div className="valign">
					                <h2 className="big" style={{ paddingTop: '20px'}}><strong>Escríbenos!</strong></h2>
                                    <div className="row">
                                        <form className="col s8" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="input-field col s12 fix-form-div">
                                                    <i className="material-icons prefix left icon-usuario small" />
                                                    <input 
                                                        type="text" 
                                                        placeholder="Nombre" 
                                                        name="nombre" 
                                                        className="cajas-mobile"
                                                        value={stateForm.nombre}
                                                        onChange={onChangeForm}
                                                        autoComplete="off"
                                                    />
                                                    {stateForm.error.nombre ? <span className="helper-text" data-error="wrong" data-success="right">{stateForm.error.nombre}</span> :  ""}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="input-field col s12 fix-form-div">
                                                    <i className="material-icons prefix left icon-mail small" />
                                                    <input 
                                                        type="text" 
                                                        placeholder="Email" 
                                                        name="email" 
                                                        className="cajas-mobile"
                                                        value={stateForm.email}
                                                        onChange={onChangeForm} 
                                                        autoComplete="off"
                                                    />
                                                    {stateForm.error.email ? <span className="error-input">{stateForm.error.email}</span> :  ""}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="input-field col s12 fix-form-div">
                                                    <i className="material-icons prefix left icon-paper-plane-empty small" />
                                                    <textarea 
                                                        placeholder="Mensaje"  
                                                        name="mensaje" 
                                                        className="materialize-textarea cajas-mobile"
                                                        value={stateForm.mensaje}
                                                        onChange={onChangeForm} 
                                                        autoComplete="off"
                                                    />
                                                    {stateForm.error.mensaje ? <span className="error-input">{stateForm.error.mensaje}</span> :  ""}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="input-field col s6">
                                                    <input type="submit" value="Enviar" className="ninja-forms-field btn btn-black-contacto"/>
                                                </div>
                                                <span className="helper-text" data-error="wrong" data-success="right">{statemessage}</span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* form */}
                        <div className="col m5 hide-on-small-only">
                            <div className="valign-wrapper">
                                <div className="valign">
                                    <p><i className="material-icons prefix left icon-pin small" aria-hidden="true"></i>
                                    <a href="https://goo.gl/maps/eVDabhBFzn72" target="_blank" title="Ver Google Map" style={{ color: '#fff'}}>Nueva de Lyon 145 Piso 2,<br />Providencia, Santiago Chile.</a></p>
                                    <p><i className="material-icons prefix left icon-mail small" aria-hidden="true"></i>
                                <a href="mailto:panel@cadem.cl" title="Enviar Email a panel@cadem.cl" style={{ color: '#fff'}}>panel@cadem.cl</a><br />
                                <a href="tel:+56224386500" title="Llamar a 224386500" style={{ color: '#fff'}}>562 2438 6500</a></p>
                                <div>
                                    <em>Cadem Online es una plataforma de<br />
                                        <a href="http://www.cadem.cl" target="_blank"><img src="img/cadem_fondo-negro.png" className="mini-logo svg" alt="" /></a>
                                    </em>
                                </div>
                                </div>
                            </div>
                        </div>
                        {/* end form */}
                    </div>
                </main>                
                <footer className="page-footer black">
                    <div className="container">
                        <div className="row">
                            <div className="col s4">
                                <a href="http://www.cadem.cl" target="_blank"><img src="img/LOGO-CADEM-ONLINE_BLANCO.svg" alt=""  style={{height: '50px', paddingTop: '10px'}} /></a>
                            </div>
                            <div className="col s4 center-align" style={{ paddingTop: '1vh' }}>
                                <a href="/terminos-y-condiciones/" className="white-text link" target='_blank'>Términos y condiciones</a>
                            </div>
                            <div className="col s4 right-align">
                                <a href="https://goo.gl/maps/eVDabhBFzn72" target="_blank" title="Ver Google Map" className="white-text"><i className="material-icons icon-pin small" aria-hidden="true"></i></a>
                                <a href="mailto:panel@cadem.cl" className="white-text"><i className="material-icons icon-mail small" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </footer>
                
            </div>
        </section>
    )
};
