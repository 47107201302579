import React from 'react';
import {ModalVideo} from '../ui/ModalVideo';
import {TerminosyCondiciones} from '../ui/ModalTerminos';

export const Header = () => {
    return (
        <>
        <ul className="side-nav right-aligned" id="mobile-demo" style={{transform: 'translateX(100%)' }}>
	        <li>
                <a href="/" target="_top" className="brand-logo">
                    <img src="/img/logo-cadem-online.svg" alt="logo" />
                </a>
            </li>
	        <li style={{  paddingTop: '32px' }} className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                <a href="#color-2" target="_top" className="waves-effect waves-light btn btn-black">
                    <i className="material-icons icon-angle-right right small" aria-hidden="true"></i> Inscríbete
                </a>
            </li>
	        <ol id="menu-navegacion-no-destacado">
                <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                    <a href="#color-1" role="button">Bienvenidos</a>
                </li>
                <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                    <a href="#color-3">Ganadores</a>
                </li>
                <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                    <a href="#color-4">Galería</a>
                </li>
                <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                    <a href="#color-5">Qué es Cademonline</a>
                </li>
                <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                    <a href="#color-6">Preguntas Frecuentes</a>
                </li>
                <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                    <a href="#color-7">Contacto</a>
                </li>
            </ol>
        </ul>
        {/* <div className="sidebar valign-wrapper hide-on-med-and-down" style={{ top: '80px'}}> */}
        <div className="sidebar valign-wrapper hide-on-med-and-down" style={{ top: '80px'}}>
            <a href="#modalvideo" className="modal-trigger btn-video">VIDEO</a>              
                <ol id="menu-navegacion-no-destacado-1" className="valign">
                    <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="#color-1">Bienvenidos</a>
                    </li>
                    <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="#color-2">Inscríbete</a>
                    </li>
                    <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="#color-3">Ganadores</a>
                    </li>
                    <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="#color-4">Galería</a>
                    </li>
                    <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="#color-5">Qué es Cademonline</a>
                    </li>
                    <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="#color-6">Preguntas Frecuentes</a>
                    </li>
                    <li className="nav-click menu-item menu-item-type-custom menu-item-object-custom">
                        <a href="#color-7">Contacto</a>
                    </li>
                </ol>
        </div>
        <div className="navbar-fixed">
            <nav className="white">
                <div className="nav-wrapper">
                    <a href="/" target="_top" className="brand-logo"><img src="/img/logo-cadem-online.svg" alt=""/></a>
		            <a href="#" data-activates="mobile-demo" className="button-collapse right black-text"><i className="material-icons icon-menu small"></i></a>
                    <ul id="nav-mobile" className="hide-on-med-and-down right">
                        <li>
                            <a href="#color-2" target="_top" className="btn btn-black">
                              Inscríbete &nbsp; 
                            </a>
                        </li>
			            <li><a href="https://www.facebook.com/panelcademonline" target="_blank" className="none fix-link"><img src="/img/facebook.png" alt="" style={{ height: '60px', paddingTop:'23px'}}/></a></li>
                        <li><a href="https://www.instagram.com/cadem_online/?hl=en" target="_blank" className="none fix-link"><img src="/img/instagram.png" alt="" style={{ height: '60px', paddingTop:'23px'}}/></a></li>
                    </ul>
                </div>
            </nav>
        </div>
        <TerminosyCondiciones />
        <ModalVideo />
        </>
    )
}