import axios from 'axios';

const config = require('../../../configuration/index');

export const getGanadores = async () => {
    
    return axios.get(config.URL_JSON_GANADORES)
    .then((response) => {
        const  { data }  = response;
        return data;
    })

    
};
