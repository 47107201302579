export default [
    {
        'id': '0',
        'title' : 'Consultas y dudas',
        'subtitle' : 'Si tienes cualquier pregunta o comentario, escríbenos a <a href="mailto:panel@cadem.cl">panel@cadem.cl</a> y te ayudaremos.'
    },
    {
        'id': '1',
        'title' : '¿Qué pasa si quiero dejar de ser miembro?',
        'subtitle' : 'Para dejar de ser parte de Cadem Online, sólo tienes que enviarnos un email a <a href="mailto:panel@cadem.cl">panel@cadem.cl</a>'
    },
    {
        'id': '2',
        'title' : '¿Mi información personal está protegida?',
        'subtitle' : 'Sí. No compartimos nunca tus datos. Sólo nos interesa tu opinión.'
    },
    {
        'id': '3',
        'title' : '¿Todo es por internet?',
        'subtitle' : 'Todas las encuestas se contestan a través de internet.'
    },
    {
        'id': '4',
        'title' : '¿Tengo que pagar algo?',
        'subtitle' : 'No, contestar las encuestas no tiene ningún costo.'
    },
    {
        'id': '5',
        'title' : '¿Cuántas encuestas tengo que responder?',
        'subtitle' : 'Las que quieras. Te enviaremos encuestas de manera frecuentes y tú decides cuáles contestar.'
    },
    {
        'id': '6',
        'title' : '¿Cuánto se demora en contestar una encuesta?',
        'subtitle' : 'El rango de tiempo va desde 5-20 minutos, dependiendo del tipo de encuesta y tu conexión a internet.'
    },
    {
        'id': '7',
        'title' : '¿Quiénes pueden participar?',
        'subtitle' : 'Todos los que estén interesados, que tengan acceso a internet y revisen su correo periódicamente.  Hay algunos estudios que están dirigidos a mayores de 18 años.'
    },
    {
        'id': '8',
        'title' : '¿Cómo funciona?',
        'subtitle' : 'Primero debes registrarte <a href="#1" data-index="1" className="nav-click">aquí</a> llenando el formulario con tus datos personales. Cuando la inscripción esté completa, recibirás un correo electrónico confirmado tu inscripción, debes hacer click en ese link y listo! A partir de ese momento, comenzarás a recibir invitaciones a las encuestas en tu correo y sólo tienes que contestarlas completas a través de la plataforma.'
    },
    {
        'id': '9',
        'title' : '¿Qué beneficios tengo por participar?',
        'subtitle' : 'Recompensamos tus opiniones con premios e incentivos. Esto dependerá de los tipos de encuesta y la extensión que tengan.'
    },
    {
        'id': '10',
        'title' : '¿Cómo participar?',
        'subtitle' : 'Para ser parte de Cadem Online debes registrarte <a href="#1" data-index="1" className="nav-click">aquí</a>. Una vez que estés registrado, nosotros te enviaremos una invitación por email con un link a la encuesta para que la contestes.'
    },
    {
        'id': '11',
        'title' : '¿Por qué al contestar la primera pregunta se cierra la encuesta?',
        'subtitle' : 'Esto puedo ocurrir porque a veces la encuesta requiere algún requisito de consumo y/o perfiles específicos. Por ejemplo: Dueñas de casa. Si la primera pregunta es “¿eres dueña de casa?” y no lo eres, probablemente no puedas seguir contestando por no calzar con el perfil requerido. ¡Pero no hay problema, habrá muchas otras en las que puedas participar!'
    },
]