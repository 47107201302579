const helpers = {

    helper_years: function () {
        
        let max = new Date().getFullYear();
        const ini =  2016;
        let years = [];
        let y = 0;

        for(var i = max; i >= ini ; i--) {
            
            years[y] = {
                value: `${i}`
            };

            y++;
        }
        
        return years;
        
    },

    helpers_meses: function () {

        let meses = [
            { "id" : 1 , "mes" : "Enero"},
            { "id" : 2 , "mes" : "Febrero"},
            { "id" : 3 , "mes" : "Marzo"},
            { "id" : 4 , "mes" :"Abril"},
            { "id" : 5 , "mes" :"Mayo"},
            { "id" : 6 , "mes" :"Junio"},
            { "id" : 7 , "mes" : "Julio"},
            { "id" : 8 , "mes" : "Agosto"},
            { "id" : 9 , "mes" : "Septiembre"},
            { "id" : 10 , "mes" : "Octubre"},
            { "id" : 11 , "mes" : "Noviembre"},
            { "id" : 12 , "mes" : "Diciembre"},
        ];

        return meses;
    },

    helpers_primera_letra : function PrimeraLetra(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

};

export default helpers;