import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from 'react-router-dom';

import { DashboardRoutes } from './DashboardRoutes';
import { GanadoresList } from '../views/Ganadores/GanadoresList';
import { FormularioRegistroCademOnline } from '../views/FormularioRegistro';
import { TerminosCondicionesCademOnline } from '../views/TerminosCondiciones';

export const AppRouter = () => {
    return (
        <Router>
            <div>
                <Switch> 
                    <Route exact path="/ganadores-detalles" component={ GanadoresList } />
                    <Route exact path="/new-perfil"  component={ FormularioRegistroCademOnline } />
                    <Route path="/terminos-y-condiciones" component={TerminosCondicionesCademOnline}/>
                    <Route path="/" component={ DashboardRoutes } />
                </Switch>
            </div>
        </Router>
    )
}
