import React from 'react';

export const Bienvenidos = () => {

    const NEW_PERFIL = `#color-2`;
    
    return (
        <section className="color-1 section fp-section fp-table active fp-completely" id="section-1" data-anchor="color-1">            
            <div className="fp-tableCell">
	           <main className="valign-wrapper">
                <h6 className="sub-title animated fadeInLeft" data-anim="fadeInLeft"></h6>
                <div className="valign">
                    <div style={{ paddingBottom:'40px'}} className="big">
                        <strong className="big strong">¡Regístrate!</strong><br /> Es fácil, responde encuestas<br /> y participa por <strong>premios</strong>
                    </div>
                    <p>
                        <a href="#color-2" target="_top" className="btn-large btn-black nav-click menu-item menu-item-type-custom menu-item-object-custom">
                            <i className="material-icons icon-angle-right right small" aria-hidden="true"></i> Inscríbete
                        </a>
                    </p>
                </div>
	            </main>
            </div>
            
        </section>
    );
};